import { initializeApp } from "firebase/app";
import { getDatabase, ref } from 'firebase/database'
import { getFirestore } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from "@firebase/functions";

type firebaseConfigTypes = {
    apiKey: string,
    authDomain: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
    measurementId: string
}

const firebaseConfig:firebaseConfigTypes = {
    apiKey: "AIzaSyAp8kaZs1klCLsN2MC4sIaploA4zgdIVvs",
    authDomain: "mole-patrol-app.firebaseapp.com",
    projectId: "mole-patrol-app",
    storageBucket: "mole-patrol-app.appspot.com",
    messagingSenderId: "330132410000",
    appId: "1:330132410000:web:5041a4a3ecc0f9349d1215",
    measurementId: "G-KZDBGJZCDN"
};

const firebaseApp = initializeApp(firebaseConfig);

export const datastore = getFirestore(firebaseApp);

export const auth = getAuth(firebaseApp);

export const functions = getFunctions(firebaseApp);

export const analytics = getAnalytics(firebaseApp); 

// Reads an array of IDs from a datastore collection concurrently
export const readIds = async (collection:any, ids:any) => {
    const reads = ids.map((id: any) => collection.doc(id).get());
    const result = await(Promise.all(reads))
    return result.map(v => v.data())
};

export default firebaseApp;
