import React, { useContext } from 'react'
import styled from 'styled-components'
import TopBar from 'components/TopBar'
import Footer from 'components/Footer';
import themeProps from 'context/Theme/themes';
import { ThemeContext, themes } from 'context/Theme/Theme';


type Props = {
    center?: boolean,
    children?: React.ReactNode,
    className?: string,
    top?: React.ReactNode | null,
};

const Page: React.FC<Props> = ({ 
    center=false,
    children,
    className='', 
    top = <TopBar/>,
}) => {
    const { theme } = useContext(ThemeContext)
    const heightOffset = (() => {
        if ( top ) {
            return themes[theme].topBar.height
        } else {
            return 0
        }
    })();

    return (
    <Main className={`page ${className}`}>
        { top &&
            <>
            {top}  
            </>
        }
        <Content className={`${center ? 'center' : ''}`}>
            <>
                { top &&
                    <TopSpacer className='topspacer' />
                }
                {children}
            </>
        </Content>

        <Footer />
    </Main>
    )
}
export default Page

const TopSpacer = styled.div `
    width: 100%;
    height: ${props => props.theme.topBar.height}px;
`
// min-height: calc(100vh - ${props => props.heightOffset}px);  
const Main = styled.div`
    position: relative;
    overflow: hidden;
    background: #eee;
    background: ${props => props.theme.background};
    background: linear-gradient(180deg, ${props => props.theme.backgroundGradient[0]} 0%, ${props => props.theme.backgroundGradient[1]} 100%); 
    background-size:     contain;
    background-repeat:   no-repeat;
    background-position: top center; 
    color: ${props => props.theme.color};
`

const Content = styled.div`
    &.center {
        max-width: 800px;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    min-height: calc(100vh - ${props => props.theme.footer.height}px);  
    z-index: 1;
`

