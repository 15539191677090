import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Page from 'components/Page';
import { MobileStyles } from 'context/Theme/Mixins';

const NotFound: React.FC = ({  }) => {
    return (
        <Page> 
            <Content>
                <h1>Page Not Found</h1>
            </Content> 
        </Page>
    )
};

export default NotFound;

const Content = styled.div `
    margin-top: 150px;
    h1 {
        text-align: center;
        font-size: 65px;
    }

    ${MobileStyles(`
        h1 {
            font-size: 35px;
        }
    `)};
`