import React, { Component, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import Page from 'components/Page';
import logo from 'assets/hand_logo_wide3.png';
import { MobileStyles } from 'context/Theme/Mixins';

const Home: React.FC = () => {
    return (
        <Page top={null}>
            <Content>
                <Logo>
                    <img src={logo} className="App-logo" alt="logo" />
                </Logo>

                <Soon>
                    <h2>An Underground NFT Collection</h2>   
                </Soon>
            </Content>
        </Page>
    )
};

export default Home;

const Content = styled.div `
    padding-top: 100px;

    ${MobileStyles(`
        padding-top: 75px;
    `)};
`

const Logo = styled.div `
    max-width: 600px;
    margin: 0px auto 0px auto;
    padding: 30px;
    text-align: center;
    img {
        max-width: 100%;
    }
`

const Soon = styled.div `
    padding: 20px;
    text-align: center;
    margin-top: 5px;
    font-weight: bold;
    color: #6b7897;

    ${MobileStyles(`
        margin-top: 0px;
        h2 {
            font-size: 28px;
        }
    `)};
`