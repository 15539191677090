import React from 'react'
import styled from 'styled-components'
import { MobileStyles } from 'context/Theme/Mixins'

interface Props {

}

const Footer: React.FC<Props> = ({

}) => (
    <Content>
        <Center>
            Copyright © {new Date().getFullYear()} Mole Patrol, LLC
        </Center>
    </Content>
)

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${props => props.theme.footer.height}px;
`
const Center = styled.div`
    text-align: center;

    ${MobileStyles(`
        height: auto;
        padding-bottom: 30px;
    `)};
`

export default Footer