import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Round } from 'context/Theme/Mixins';
import banner from 'assets/hand_logo_wide.png';
import square from 'assets/logo_square.png';


interface Props {
    shape?: 'wide' | 'square' | 'round',
    to?: string,
    href?: string,
    size?: number
    display?: 'inline-block' | 'block'
}

const Logo: React.FC<Props> = ({
    shape='wide',
    to,
    href,
    size = 40,
    display = 'inline-block'
}) => {

    const logo = (() => {
        if ( shape === 'square' || shape === 'round' ) {
            return square
        } else {
            return banner
        }
    })();

    return (
    <Content display={display} size={size} className={`${shape}`}>  
        { href && 
            <a href={href}>
                <img src={logo} alt='logo' />
            </a>
        }

        { to &&
            <Link to={to}>
                <img src={logo} alt='logo' />
            </Link>
        }

        { (!href && !to) &&
            <img src={logo} alt='logo' />
        }
    </Content>
    );
};

interface StyleProps {
    display: string,
    size: number
}

const Content = styled.div<StyleProps>`
    display: ${props => props.display};
    &.wide {
        img {
            height: ${props => props.size}px;
        }
    }
    &.round, &.square {
        text-align: center;
        line-height: ${props => props.size}px;
        width: ${props => props.size}px;
        height: ${props => props.size}px;
        padding: 15px;
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &.round {
        ${Round()};
    }
`;

export default Logo;
