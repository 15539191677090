import { css } from 'styled-components'
// import { MobileStyles } from 'contexts/Theme/Mixins'

export const BorderRadius = (radius:number=20) => css`
    -webkit-border-radius: ${radius}px;
    -moz-border-radius: ${radius}px;
    border-radius: ${radius}px;
`;

export const Round = () => css`
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
`;

export const Transition = (property:string, duration:string='.3s', fn:string='linear' ) => css`
    transition-property: ${property};
    transition-duration: ${duration};
    transition-timing-function: ${fn};
`;

export const DropShadow = () => css`
    box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.3);
`;

export const GlassBackground = () => css`
    backdrop-filter: blur(5px); 
    background-color: rgba(255, 255, 255, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
`;


/*
${MobileStyles(`

`)};
*/
export const MobileStyles = (style:any, maxWidth:number=767) => css`   
    @media only screen and (max-width: ${maxWidth}px) {
        ${style}
    }
`;

