import React from 'react'
import styled from 'styled-components'
import Logo from 'components/Logo'
import { Link } from 'react-router-dom'
import { useAuth } from 'context/AuthContext'

interface Props {
    right?: boolean,
}

const TopBar: React.FC<Props> = ({
    right = true
}) => { 
    const { currentUser } = useAuth() 

    return (
    <Content>
        <Bar>
            <Nav>
                <Left>
                    <Link to='/'>
                        <Logo />
                    </Link>
                </Left>

                <Center>

                </Center>

                { right &&
                <Right>  
                   
                </Right>
                }
            </Nav> 
        </Bar>
    </Content>
    )
}

const Content = styled.div `

`


const Bar = styled.div`
    display: flex;
    align-items: center;
    background-color: ${(props:any) => props.theme.topBar.background};
    border-bottom: 1px solid ${(props:any) => props.theme.topBar.borderColor};
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 2;
    height: ${props => props.theme.topBar.height}px;
`


const Nav = styled.div `
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    .logo {
        cursor: pointer;
        label {
            cursor: pointer;
        }
    }
`

const Left = styled.div `
    padding-left: 15px;
    display: flex;
    justify-content: left;
`

const Center = styled.div `

`

const Right = styled.div `
    padding-right: 15px;
    display: flex;
    justify-content: right;
`

export default TopBar