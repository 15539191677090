import React from 'react';
import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Home from 'views/Home';
import NotFound from 'views/NotFound';

import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { isLocalhost } from 'utils/utils';
import firebaseApp from './firebase';
import { AuthProvider } from 'context/AuthContext';
import { ThemeProvider, ThemeStore } from 'context/Theme';


if ( isLocalhost ) {
    const auth = getAuth()
    connectAuthEmulator(auth, "http://localhost:9099")

    const db = getDatabase()
    connectDatabaseEmulator(db, "localhost", 9000)

    const ds = getFirestore()
    connectFirestoreEmulator(ds, "localhost", 8080)

    const functions = getFunctions(firebaseApp);
    connectFunctionsEmulator(functions, "localhost", 5001)
} else {
    /*
    const tagManagerArgs = {
        gtmId: ''
    }
    TagManager.initialize(tagManagerArgs)
    */
}


const App: React.FC = () => {
    return (
    <Providers>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    </Providers>
    );
};

interface ProviderProps {
    children?: React.ReactNode
}
const Providers: React.FC<ProviderProps> = ({ children }) => {
    return (
        <AuthProvider>
            <ThemeStore>
                <ThemeProvider>
                    <>
                        {children}
                    </>
                </ThemeProvider>
            </ThemeStore>
        </AuthProvider>
    );
};

export default App;

