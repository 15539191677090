import React, { createContext, useState, useCallback, useContext } from "react";
import { AnyObject } from "immer/dist/internal";
import { ThemeProvider as StyledThemeProvider, createGlobalStyle } from "styled-components";
import themeProps from './themes'

interface ThemeProps {
    children?: React.ReactNode
}

export interface ThemeContextProps {
    theme: string,
    changeTheme: (theme:string) => void
}

export const ThemeContext = createContext<ThemeContextProps>({ 
    theme: 'dark',
    changeTheme: () => {},
})

export const ThemeStore: React.FC<ThemeProps> = ({ children }) => {
    const [theme, setTheme] = useState<string>('dark')

    const onChangeTheme = useCallback((theme:string) => {
        setTheme(theme)
    }, [setTheme])

    return <ThemeContext.Provider value={{ 
            changeTheme: onChangeTheme, 
            theme
        }}>
            {children}
        </ThemeContext.Provider>;
}

export const themes:AnyObject = themeProps


const GlobalStyle = createGlobalStyle<{theme:any}>`
    * {
        margin: 0;
        padding: 0;
    }
    body {
        color: ${({ theme }) => theme.color};
        background: ${({ theme }) => theme.background};
    }

    body, p {
        font-size: 17px;
        line-height: 23px;
    }

    p {
        margin-bottom: 15px;
    }

    a {
        color: ${({ theme }) => theme.color};
        &:hover {
            filter: brightness(1.1);
        }
    }

    input {
        box-sizing: border-box;
        border-radius: ${(props) => props.theme.form.input.radius}px;
        color: ${(props) => props.theme.color};
        padding: ${(props) => props.theme.form.input.padding}px;
        background: ${(props) => props.theme.form.input.background};
        border-top: 1px solid rgba(0, 0, 0, 0.3);
        border-left: 1px solid rgba(0, 0, 0, 0.3); 
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        border-right: 1px solid rgba(255, 255, 255, 0.05); 
        border: none;
        font-size: 16px;
        :focus {
            outline: none !important;
        }
        &:hover {
            filter: brightness(1.1);
        }
    }
`

export const ThemeProvider:React.FC<ThemeProps> = ({ children }) => {
    const { theme } = useContext(ThemeContext);
    return (
        <StyledThemeProvider theme={themes[theme]}>
        <GlobalStyle />
        {children}
        </StyledThemeProvider>
    );
};