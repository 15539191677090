import React, { createContext, useContext, useEffect, useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword,
         signInWithCustomToken, signOut, onAuthStateChanged } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { collection, DocumentData, getDocs, query, where } from "firebase/firestore";
import { auth, functions, datastore } from '../firebase';

export type UserProps = {
    name: string
}

export type AuthContextProps = {
    currentUser: UserProps | null,
    resetPassword: (email: string) => any,
    signup: (email: string, password: string) => void,
    signin: (email: string, password: string) => any,
    signout: () => void
}

export function useAuth() {
    return useContext(AuthContext)
}

const AuthContext = createContext<AuthContextProps>({ 
    currentUser: null,
    resetPassword: () => {},
    signup: () => {},
    signin: () => {},
    signout: () => {}
})

type ProviderProps = {
    children?: React.ReactNode,
};

const AuthProvider: React.FC<ProviderProps> = ({ children }) => {
    //const getAuthToken = httpsCallable(functions, 'getAuthToken')
    //const getUser = httpsCallable(functions, 'getUser')
    const importLegacyUser = httpsCallable(functions, 'importLegacyUser')
    const resetUserPassword = httpsCallable(functions, 'resetUserPassword')
    const [currentUser, setCurrentUser] = useState<any | null>(null); 


    const signup = async (email:string, password:string) => {
        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
    }

    const resetPassword = async (email:string) => {
        return resetUserPassword(email).then((response) => {
        
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
        })
    }

    const signin = async (email:string, password:string) => {
        return signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // const user = userCredential.user;
                    setCurrentUser(user)
                    return 'signin/success'
                })
                .catch((error) => {
                    //const errorCode = error.code
                    //const errorMessage = error.message
                    return error.code
                });
           
        // TOOD: Move the logic below out of this function?
        var user: DocumentData | null = null
        const usersRef = collection(datastore, "users")
        const q = query(usersRef, where("login", "==", email))
        const snap = await getDocs(q)
        snap.forEach((doc) => {
            const data = doc.data()
            if ( data.login == email ) {
                user = data
            }
        })

        if ( user ) {            
            return signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // const user = userCredential.user;
                    setCurrentUser(user)
                    return 'signin/sucess'
                })
                .catch((error) => {
                    //const errorCode = error.code
                    //const errorMessage = error.message
                    if ( error.code == 'auth/user-not-found' ) {

                    }
                    return error.code
                });
        }

        // IF user doesn't exist check to see if they are a legacy user
        var legacyUser = null
        const lUsersRef = collection(datastore, "legacyUsers")
        const lq = query(lUsersRef, where("login", "==", email))
        const lSnap = await getDocs(lq)
        lSnap.forEach((item) => {
            const data = item.data()
            if ( data.login == email ) {
                legacyUser = data
            }
        })

        if ( legacyUser ) {
            return await importLegacyUser({email: email})
                .then(() => {
                    return 'signin/import-legacy-user'
                })
                .catch((error) => {
                    return(error.code)
                })
        }
        return 'signin/user-not-found'
    }

    const signout = async () => {
        return signOut(auth)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
        })
        return unsubscribe
    }, [])


    /*
    const getAccountUser = async () => {
        if ( !address ) return

        // Update the lastLogin info
        update(ref(database, `users/${address}`), {
            uid: address,
            lastLogin: Date.now()
        })

        getAuthToken({ 
            address: address
        }).then((result) => {
            const data:any = result.data        

            signInWithCustomToken(auth, data.token)
                .then((userCredential) => {
                    getUser({
                        id: address
                    }).then((result) => {
                        setAccountUser(result.data)
                    })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        })
    }
    */

    return  (
        <AuthContext.Provider value={{ 
            currentUser: currentUser,
            resetPassword: resetPassword,
            signup: signup,
            signin: signin,
            signout: signout
         }}>
            {children}
        </AuthContext.Provider>
    );
};
export  { AuthContext,  AuthProvider };
