const merge = require('lodash.merge');

const radius = [
    15
]

const defaultTheme = {
    background: '#222',
    backgroundGradient: ['#394150', '#282c34'],
    color: '#eee',
    buttons: {
        radius: radius[0],
        primary: {
            background: '#ec1b24',
            color: '#f6f6f6'
        },
        secondary: {
            background: '#ec1b24',
            color: '#f6f6f6'
        },
        tertiary: {
            background: '#ec1b24',
            color: '#f6f6f6'
        },
    },
    card: {
        background: '#2a2a2a',
        radius: radius[0]
    },
    error: {
        background: 'linear-gradient(to right, rgba(216, 10, 10, .25), rgba(0,0,0,0))',
        color: '#fff',
        icon: 'rgba(216, 10, 10, 1)'
    },
    footer: {
        height: 60
    },
    form: {
        input: {
            background: '#333',
            padding: 20,
            radius: radius[0]
        }
    },
    spacing: {
        1: 4,
        2: 8,
        3: 16,
        4: 24,
        5: 32,
        6: 48,
        7: 64,
    },
    topBar: {
        background: '#111',
        borderColor: '#151515',
        height: 75
    },
    warning: {
        background: 'linear-gradient(to right, rgba(255, 174, 0, .25), rgba(0,0,0,0))',
        color: '#fff',
        icon: 'rgba(228, 163, 23, 1)'
    }
}

// Theme definition and overrides
const themeProps = {
    dark: merge({}, defaultTheme, {
        background: '#394150',
        backgroundGradient: ['#394150', '#282c34'],
        color: '#eee'
    }),
    light: merge({}, defaultTheme, {
        background: '#eee',
        backgroundGradient: ['#eee', '#eee'],
        color: '#333'
    })
}


export default themeProps